import { useContext } from "react"
import { Link, useLocation } from "react-router-dom"
import { LOGOUT } from "../components/ApiRoutes"
import { getData, loader, loaderASec } from "../components/Helper"
import { useNavigate } from "react-router-dom"
import Context from "../components/Context"
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { ToolTip } from "../components/ButtonHelper"

export const SideBar = () => {
    const navigate = useNavigate()
    const [, setContext] = useContext(Context)

    const logout = (e) => {
        e.preventDefault()
        loader(true)
        getData(LOGOUT, true, (res) => {
            if (res.success) {
                loaderASec()
                setContext(prevState => ({ ...prevState, auth: null }))
                localStorage.removeItem("jobman-token");
                navigate("/login")
            }
        })
    }

    return (
        <>
            <div className="my-account-menu">
                <ul className="nav account-menu-list flex-column">
                    <CustomLink title="Dashboard" url="/my-account/dashboard" icon={<DashboardCustomizeIcon className="me-2" />} checkurl={['dashboard']} extraurl={['/my-account', '/my-account/']} />
                    <CustomLink title="My Profile" url="/my-account/my-profile" icon={<GroupAddIcon className="me-2" />} checkurl={['my-profile']} />
                    <CustomLink title="Change Password" url="/my-account/change-password" icon={<VpnKeyIcon className="me-2" />} checkurl={['change-password']} />
                    <CustomLink title="Request A Quote" state={{goBack: true}} url="/request-a-quote" icon={<RequestQuoteIcon className="me-2" />} checkurl={['request-a-quote']} />
                    <CustomLink title="My Lead" url="/my-account/lead-list" icon={<AccountTreeIcon className="me-2" />} checkurl={['lead-list']} extraurl={['/my-account/lead-view']} />
                    <ToolTip title="Log out" placement="right">
                        <li><Link onClick={logout} ><ExitToAppIcon className="me-2" /> Log out</Link></li>
                    </ToolTip>
                </ul>
            </div>
        </>
    )
}


export default SideBar;

const CustomLink = (props) => {
    const locatoin = useLocation()
    const checkLocation = (url, base = false) => {
        let match = url.some((l) => locatoin.pathname.match(l));
        if (base && !match) {
            match = base.some((l) => locatoin.pathname === l);
        }
        return match;
    }

    return (<>
        <ToolTip title={props.title} placement="right">
            <li>
                <Link to={props.url} state={props.state ?? null} className={checkLocation(props.checkurl, props.extraurl) ? 'active' : ''}>{props.icon} {props.title}  </Link>
            </li>
        </ToolTip>
    </>)
}