
import { Link, Outlet, useLoaderData, useNavigate, useNavigation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { useContext, useEffect, useState } from "react";
import { scrollbarSetToTop } from "../components/Helper";
import SideBar from "./SideBar";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Context from "../components/Context";
import Banner from "../components/Banner";
import { Loader1 } from "../components/Loader";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import AdminHeader from "./AdminHeader";

export const Root = () => {
    const user = useLoaderData();
    const [context, setContext] = useState({ auth: user, site: null, page: null });
    const navigation = useNavigation();
    const navigate = useNavigate();

    useEffect(() => {
        if(localStorage.getItem('jobman-admin')){
            navigate("/admin")
        }else{
            if (user && user.message === "Unauthenticated.") {
                localStorage.removeItem("jobman-token");
                localStorage.removeItem("jobman-admin");
                window.location.href = '/login'
            }
        }
    }, [navigate, user])

    useEffect(() => {
        scrollbarSetToTop()
    }, [navigation])

    useEffect(() => {
        AOS.init({ duration: 500, easing: 'ease', once: false });
    }, [])

    return (
        <Context.Provider value={[context, setContext]} >
            <Header />
            <Outlet />
            <Footer />
        </Context.Provider>
    )
}

export const Layout = () => {
    const [context] = useContext(Context);

    return (
        <>
            <div className="text-center postloader" id="loading-data-loader">
                <Loader1 />
            </div>
            <div className="" id="loading-data-section">
                {!context.page?.banner && <Banner title={context.page?.title} url={context.page?.banner_img} />}
                <Outlet />
            </div>
        </>
    )
}

export const AuthLayout = () => {
    const [context] = useContext(Context);

    return (
        <>
            <Banner title={context.page?.title} url={context.page?.banner_img} />
            <div className="section section-padding margin-top">
                <div className="container">
                    <div className="row">
                        <div className="col-12 ps-0">
                            <ul className="breadcrumb">
                                <li><Link to="/my-account"><DashboardCustomizeIcon /></Link></li>
                                <li><NavigateNextIcon /></li>
                                {context.page?.breadcrumbs}
                                <li className="active breadCrumbActive">{context.page?.title}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row gy-4">
                        <div className="col-xl-2 col-md-3 px-0" style={{ backgroundColor: "#f7f7f7" }}>
                            <SideBar />
                        </div>
                        <div className="col-xl-10 col-md-9 border  pt-0">
                            <div className="tab-content my-account-tab">
                                <div className="tab-pane fade show active" id="pills-dashboard">
                                    <div className="my-account-dashboard account-wrapper">
                                        <div className="text-center" id="loading-data-loader">
                                            <Loader1 />
                                        </div>
                                        <div className="welcome-dashboard1  px-4 pb-4" id="loading-data-section">
                                            <Outlet />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export const AdminLayout = () => {
    const user = useLoaderData();
    const [context, setContext] = useState({ auth: user, site: null, page: null, admin: true });
    const navigation = useNavigation();
    const navigate = useNavigate();

    useEffect(() => {
        if (user && user.error && user.success === "Unauthorized") {
            localStorage.removeItem("jobman-token");
            localStorage.removeItem("jobman-admin");
            window.location.href = '/admin/login'
        }
    }, [navigate, user])

    useEffect(() => {
        scrollbarSetToTop()
    }, [navigation])

    useEffect(() => {
        AOS.init({ duration: 500, easing: 'ease', once: false });
    }, [])

    return (
        <>
            <Context.Provider value={[context, setContext]} >
                <AdminHeader />
                <Banner title={context.page?.title} url={context.page?.banner_img} />
                
                <div className="section section-padding margin-top" style={{ minHeight: "80vh" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 ps-0">
                                <ul className="breadcrumb">
                                    <li><Link to="/admin"><DashboardCustomizeIcon /></Link></li>
                                    <li><NavigateNextIcon /></li>
                                    {context.page?.breadcrumbs}
                                    <li className="active breadCrumbActive">{context.page?.title}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="row gy-4">
                            <div className="col-xl-12 col-md-12 border  pt-0">
                                <div className="tab-content my-account-tab">
                                    <div className="tab-pane fade show active" id="pills-dashboard">
                                        <div className="my-account-dashboard account-wrapper">
                                            <div className="text-center" id="loading-data-loader">
                                                <Loader1 />
                                            </div>
                                            <div className="welcome-dashboard1  px-4 pb-4" id="loading-data-section">
                                                <Outlet />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section footer-section">
                    <div className="copyright">
                        <div className="container">
                            <div className="copyright-text">
                                <p>Copyright © 2023 – Joinery By Design Pty. Ltd.  All rights reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Context.Provider>
        </>
    )
}

export default Root;