import { Tooltip, OverlayTrigger } from 'react-bootstrap';

export const ToolTip = (props) => {

    return (

        <OverlayTrigger placement={props.placement ?? "top"} trigger={['click', 'focus', 'hover']} overlay={<Tooltip> {props.title} </Tooltip>}>
            {props.children}
        </OverlayTrigger>
    )
}

export const EditButton = (props) => {

    return (
        <ToolTip title={props.title ?? 'Edit'}>
            <button className="btn btn-sm btn-outline-secondary me-1 action-btn" onClick={props.action ?? null}>
                <i className="bi bi-pencil-fill"></i>
            </button>
        </ToolTip>
    )
}

export const DeleteButton = (props) => {
    return (
        <ToolTip title={props.title ?? 'Delete'}>
            <button className="btn btn-sm btn-outline-danger me-1 action-btn" data-bs-toggle="modal" data-bs-target={props.modal_id} onClick={props.action ?? null}>
                <i className="bi bi-trash-fill"></i>
            </button>
        </ToolTip>
    )
}


export const PermissionButton = (props) => {
    return (
        <ToolTip title={props.title ?? 'Permission'}>
            <button className="btn btn-sm btn-outline-primary me-1 action-btn" data-bs-toggle="modal" data-bs-target={props.modal_id} onClick={props.action ?? null} >
                <div className="spinner-border spinner-border-sm d-none" role="status"></div>
                <i className="bi bi-ui-checks-grid"></i>
            </button>
        </ToolTip>
    )
}

export const ReloadButton = (props) => {
    return (
        <ToolTip title={props.title ?? 'Syncing'}>
            <button className="btn btn-sm btn-outline-success me-1 action-btn" onClick={props.action ?? null}>
                <i className="bi bi-arrow-clockwise"></i>
            </button>
        </ToolTip>
    )
}

// to animate svg button change  props.load to 1.075268817204301s
export const SvgReloadButton = (props) => {
    return (
        <ToolTip title={props.title ?? 'Syncing'}>
            <button className="btn btn-sm btn-outline-success me-1 action-btn" onClick={props.action ?? null} id={props.id ?? null}>
                <svg style={{ background: "#fff", display: "block" }} width="25px" height="25px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <g>
                        <path d="M50 30A20 20 0 1 0 69.99013120731463 49.37178481843744" fill="none" stroke="#198754" strokeWidth="6"></path>
                        <path d="M49 19L49 41L60 30L49 19" fill="#198754"></path>
                        <animateTransform id={props.id ? 'animate'+props.id : null} attributeName="transform" type="rotate" repeatCount="indefinite" dur="100s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                    </g>
                </svg>
            </button>
        </ToolTip>
    )
}


export const ActiveButton = (props) => {
    return (
        <ToolTip title={props.title ?? 'Activate'}>
            <button className="btn btn-sm btn-outline-success me-1 action-btn" onClick={props.action ?? null}>
                <i className="bi bi-check-circle-fill"></i>
            </button>
        </ToolTip>
    )
}

export const DeactiveButton = (props) => {
    return (
        <ToolTip title={props.title ?? 'Deactivate'}>
            <button className="btn btn-sm btn-outline-warning me-1 action-btn" onClick={props.action ?? null}>
                <i className="bi bi-x-circle-fill"></i>
            </button>
        </ToolTip>
    )
}

export const InProgressButton = (props) => {
    return (
        <ToolTip title={props.title ?? 'In Progress'}>
            <button className="btn btn-sm btn-outline-info me-1 action-btn icon-black" onClick={props.action ?? null}>
                <i className="bi bi-hourglass-split"></i>
            </button>
        </ToolTip>
    )
}

export const HoldButton = (props) => {
    return (
        <ToolTip title={props.title ?? 'Hold'}>
            <button className="btn btn-sm btn-outline-secondary me-1 action-btn" onClick={props.action ?? null}>
                <i className="bi bi-pause-circle"></i>
            </button>
        </ToolTip>
    )
}

export const CompleteButton = (props) => {
    return (
        <ToolTip title={props.title ?? 'Complete'}>
            <button className="btn btn-sm btn-outline-success me-1 action-btn" onClick={props.action ?? null}>
                <i className="bi bi-check-circle"></i>
            </button>
        </ToolTip>
    )
}

export const AddButton = (props) => {
    return (
        <ToolTip title={props.title ?? 'Add'}>
            <button id={props.id ?? null} className="btn btn-sm btn-outline-info me-1 action-btn" onClick={props.action ?? null}>
                <i className="bi bi-plus-circle-fill"></i>
            </button>
        </ToolTip>
    )
}

export const ListButton = (props) => {
    return (
        <ToolTip title={props.title ?? 'List'}>
            <button id={props.id ?? null} className="btn btn-sm btn-outline-info me-1 action-btn" onClick={props.action ?? null}>
                <i className="bi bi-list-task"></i>
            </button>
        </ToolTip>
    )
}


export const CancelButton = (props) => {
    return (
        <ToolTip title={props.title ?? 'Cancelled'}>
            <button className="btn btn-sm btn-outline-danger me-1 action-btn" data-bs-toggle="modal" data-bs-target={props.modal_id} onClick={props.action ?? null}>
                <i className="bi bi-x-circle-fill"></i>
            </button>
        </ToolTip>
    )
}

export const NoteButton = (props) => {
    return (
        <ToolTip title={props.title ?? 'Note'}>
            <button className="btn btn-sm btn-outline-dark me-1 action-btn icon-white" onClick={props.action ?? null}>
                <i className="bi bi-card-checklist"></i>
            </button>
        </ToolTip>
    )
}

export const ViewButton = (props) => {
    return (
        <ToolTip title={props.title ?? 'View'}>
            <button className="btn btn-sm btn-outline-info me-1 action-btn" onClick={props.action ?? null}>
                <i className="bi bi-eye-fill"></i>
            </button>
        </ToolTip>
    )
}

export const ViewButtonModal = (props) => {
    return (
        <ToolTip title={props.title ?? 'View'}>
            <button className="btn btn-sm btn-outline-info me-1 action-btn" data-bs-toggle="modal" data-bs-target={props.modal_id} onClick={props.action ?? null}>
                <i className="bi bi-eye-fill"></i>
            </button>
        </ToolTip>
    )
}

export const NoteButtonModal = (props) => {
    return (
        <ToolTip title={props.title ?? 'Note'}>
            <button className="btn btn-sm btn-outline-dark me-1 action-btn icon-white" data-bs-toggle="modal" data-bs-target={props.modal_id} onClick={props.action ?? null}>
                <img src="/images/note.png" className="h-20px" alt="progress" />
            </button>
        </ToolTip>
    )
}

export const AttachmentButtonModal = (props) => {
    return (
        <ToolTip title={props.title ?? 'Attachment'}>
            <button className="btn btn-sm btn-outline-info me-1 action-btn" data-bs-toggle="modal" data-bs-target={props.modal_id} onClick={props.action ?? null}>
                <i className="bi bi-paperclip"></i>
            </button>
        </ToolTip>
    )
}


export const AttachmentButton = (props) => {
    return (
        <ToolTip title={props.title ?? 'Download'}>
            <button className="btn btn-sm btn-outline-info me-1 action-btn" onClick={props.action ?? null}>
                <i class="bi bi-file-earmark-pdf"></i>
            </button>
        </ToolTip>
    )
}

export const AddButtonModal = (props) => {
    return (
        <ToolTip title={props.title ?? 'Add'}>
            <button id={props.id ?? null} className="btn btn-sm btn-outline-info me-1 action-btn" data-bs-toggle="modal" data-bs-target={props.modal_id} onClick={props.action ?? null}>
                <i className="bi bi-plus-circle-fill"></i>
            </button>
        </ToolTip>
    )
}

export const ListButtonModal = (props) => {
    return (
        <ToolTip title={props.title ?? 'List'}>
            <button id={props.id ?? null} className="btn btn-sm btn-outline-info me-1 action-btn" data-bs-toggle="modal" data-bs-target={props.modal_id} onClick={props.action ?? null}>
                <i className="bi bi-list-task"></i>
            </button>
        </ToolTip>
    )
}

export const OnGoingButton = (props) => {
    return (
        <ToolTip title={props.title ?? 'On-going'}>
            <button className="btn btn-sm btn-outline-info me-1 action-btn" onClick={props.action ?? null}>
                <i className="bi bi-forward-fill"></i>
            </button>
        </ToolTip>
    )
}

export const EditButtonModal = (props) => {
    return (
        <ToolTip title={props.title ?? 'Edit'}>
            <button className="btn btn-sm btn-outline-secondary me-1 action-btn" data-bs-toggle="modal" data-bs-target={props.modal_id} onClick={props.action ?? null}>
                <i className="bi bi-pencil-fill"></i>
            </button>
        </ToolTip>
    )
}

export const DragButton = (props) => {
    return (
        <ToolTip title={props.title ?? 'Drag'}>
            <button className="btn btn-sm btn-outline-info me-1 action-btn drag">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-grip-vertical" viewBox="3 4 8 8">
                    <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                </svg>
            </button>
        </ToolTip>
    )
}

export const VerifyButton = (props) => {
    return (
        <ToolTip title={props.title ?? 'Email-verify'}>
            <button className="btn btn-sm btn-outline-success me-1 action-btn icon-white" onClick={props.action ?? null}>
                <img src="/images/user.svg" className="h-20px" alt="progress" />
            </button>
        </ToolTip>
    )
}
