import { useContext, useMemo } from "react"
import { Link, useLocation } from "react-router-dom"
import Context from "../components/Context"
import { LOGOUT } from "../components/ApiRoutes"
import { getData } from "../components/Helper"
import { useNavigate } from "react-router-dom"
const Header = () => {
    const location = useLocation()
    const [context, setContext] = useContext(Context)
    const checkLocation = (url, base = false) => {
        let match = url.some((l) => location.pathname.match(l));
        if (base && !match) {
            match = base.some((l) => location.pathname === l);
        }
        return match;
    }

    return (<>
        <div className="header-area header-sticky d-none d-lg-block">
            <div className="container position-relative">
                <div className="row align-items-center">
                    <div className="col-lg-3">
                        <div className="header-logo">
                            <Link to="/">
                                <img src="/assets/images/logo.png" width="154" height="46" alt="Logo" />
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="header-menu">
                            <NavBar checkLocation={checkLocation} />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <AuthBar context={context} setContext={setContext} checkLocation={checkLocation} />
                    </div>
                </div>
            </div>
        </div>

        <div className="header-mobile section d-lg-none">
            <div className="header-mobile-top header-sticky">
                <div className="container">
                    <div className="row row-cols-3 gx-2 align-items-center">
                        <div className="col">
                            <div className="header-toggle">
                                <button className="mobile-menu-open" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMenu">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                            </div>
                        </div>
                        <div className="col">
                            <div className="header-logo text-center">
                                <Link to="/">
                                    <img src="/assets/images/logo.png" width="154" height="46" alt="Logo" />
                                </Link>
                            </div>
                        </div>
                        <div className="col ms-auto">
                            <div className="header-meta">
                                <AuthBar context={context} setContext={setContext} checkLocation={checkLocation} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasMenu">
            <div className="offcanvas-body">
                <div className="canvas-menu">
                    <div className="admin-logobox p-2">
                        <Link to="/" title="Home">
                            <img src="/assets/images/logo.png" width="154" height="46" alt="Logo" />
                        </Link>
                        <button type="button" className="btn btn-close border-0 pt-4 px-0 float-end" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMenu" title="Menu Close"></button>
                    </div>
                    <nav className="mobile-header-menu">
                        <NavBar checkLocation={checkLocation} />
                    </nav>
                </div>
            </div>
        </div>
    </>)
}

export default Header

const NavBar = ({ checkLocation }) => {

    return (<>
        <ul className="nav-menu">
            <li className={checkLocation(['about-us']) ? 'active pe-none' : ''}>
                <Link to="/about-us" data-bs-dismiss="offcanvas" aria-label="Close">ABOUT US</Link>
            </li>
            <li className={checkLocation(['projects']) ? 'active pe-none' : ''}>
                <Link to="/projects" data-bs-dismiss="offcanvas" aria-label="Close">PROJECTS</Link>
            </li>
            <li className={checkLocation(['services']) ? 'active pe-none' : ''}>
                <Link to="/services" data-bs-dismiss="offcanvas" aria-label="Close">SERVICES</Link>
            </li>
            <li className={checkLocation(['steel-by-design']) ? 'active pe-none' : ''}>
                <Link to="/steel-by-design" data-bs-dismiss="offcanvas" aria-label="Close">STEEL BY DESIGN</Link>
            </li>
            <li className={checkLocation(['request-a-quote']) ? 'active pe-none' : ''}>
                <Link to="/request-a-quote" data-bs-dismiss="offcanvas" aria-label="Close">REQUEST A QUOTE</Link>
            </li>
        </ul>
    </>)
}

const AuthBar = ({ context, setContext, checkLocation }) => {
    const token = useMemo(() => {
        return ((context && context.auth) || localStorage.getItem('jobman-token')) ? true : false
    }, [context]);

    const navigate = useNavigate()

    const logout = (e) => {
        e.preventDefault()
        getData(LOGOUT, true, (res) => {
            if (res.success) {
                setContext(prevState => ({ ...prevState, auth: null }))
                localStorage.removeItem("jobman-token");
                navigate("/login")
            }
        })
    }

    return (<>
        {token ? <>
            <div className="header-meta">
                <div className="dropdown">
                    <Link className="action" data-bs-toggle="dropdown"><i className="pe-7s-user"></i></Link>
                    <ul className="dropdown-menu dropdown-profile dropdown-menu-end ">
                        <li className={checkLocation(['dashboard'], ['/my-account', '/my-account/']) ? 'active pe-none' : ''}>
                            <Link to="/my-account/dashboard">Dashboard</Link>
                        </li>
                        <li className={checkLocation(['my-profile']) ? 'active pe-none' : ''}>
                            <Link to="/my-account/my-profile">My Profile</Link>
                        </li>
                        <li className={checkLocation(['change-password']) ? 'active pe-none' : ''}>
                            <Link to="/my-account/change-password">Change Password</Link>
                        </li> 
                        <li className={checkLocation(['home']) ? 'active pe-none' : ''}>
                            <Link to="/login" onClick={logout}>Logout</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </> : <>
            <div className="text-end">
                <Link to="/login" className="btn btn-sm btn-primary">Login</Link>
            </div>
        </>}
    </>)
}