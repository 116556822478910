// Settings Routes
export const GET_SITE_DATA = "get-site-data"
export const GET_AUTH_USER = "get-auth-user-info"
export const GET_DASHBOARD_DATA = "get-dashboard"

// Auth Routes
export const LOGIN = "login"
export const CREATE_CUSTOMER = "create-contact"
export const UPDATE_CUSTOMER = "update-contact"
export const CONTACT_TYPES = "contact-types"
export const COUNTRY_LIST = "country-list"
export const FORGET_PASSWORD = "forgot-password"
export const VERIFY_OTP = "verify-otp"
export const RESET_PASSWORD = "reset-password"
export const CHANGE_PASSWORD = "change-password"
export const LOGOUT = "logout"


// Lead Routes
export const LEAD_TYPE = "get-leads-type-list"
export const CREATE_LEAD = "create-leads"
export const LEAD_LIST = "get-leads-list"
export const LEAD_DELETE = "lead-delete"
export const UPDATE_LEAD = "get-update-leads"
export const WORKFLOW_ID = "get-workflow-list"
export const QUOTE_LIST = "get-lead-quote"
export const LEAD_STATUS_LIST = "lead-status-list"

export const QUOTE_STATUS_LIST = "quotes-status-list"
export const QUOTE_STATUS_CHANGE = "quote-status-change"

export const GET_JOB_LIST = "get-job-list"
export const GET_LEAD_FILE = "get-lead-files"
export const UPLOAD_FILE = "master-upload-file"


// Admin Routes

export const ADMIN_LOGIN = "admin/login"
export const GET_ADMIN_USER = "admin/get-auth-user-info"
export const ADMIN_FORGET_PASSWORD = "admin/forgot-password"
export const ADMIN_RESET_PASSWORD = "admin/reset-password"
export const ADMIN_LOGOUT = "admin/logout"
export const ADMIN_LEADS = "admin/get-lead-list"
export const ADMIN_JOB_LIST = "admin/get-job-list"
export const ADMIN_QUOTE = "admin/get-quote-list"
export const LEAD_SYNC = "admin/update-lead-sync-status"
export const CONTACT_LIST = "admin/get-contact-list"
export const ADMIN_CHANGE_PASSWORD = "admin/change-password"
export const ADMIN_DASHBOARD_DATA = "admin/get-dashboard-data"
export const JOB_SYNC_STATUS_UPDATE = "admin/update-job-sync-status"
export const QUOTE_SYNC_STATUS_UPDATE = "admin/update-quote-sync-status"
export const SYNC_JOBMAN = "admin/sync-jobman"

